

// TOP BAR
.top-bar-wrapper{
	padding: 5px;
	background-color: $color-grey;
	
	
	
	
	&,
	a{
		color: #fff;
	}
	
	a:hover{
		color: $color-primary;
	}
	
	.social-links,
	.language-menu{
		@include col($xs:6, $padding: false);
		padding: 0px 15px;
		
		
	}
	
	.social-links{
		
		label{
			font-size: 0.8rem;
			font-style: italic;
			margin-right: 8px;
		}
		
		ul{
			display: inline-block;
			margin: 0;
			
			li{
				display: inline-block;
				margin: 0;
				list-style: none;
				
				a{
					margin-right: 8px;
				}
				
				a span{
					display: none;
				}
			}
		}
	}
	
	.language-menu{
		text-align: right;
		label{
			display: inline;
			font-size: 0.8rem;
			margin-right: 5px;
		}
		
		ul{
			@include reset-list;
			display: inline-block;
			
			a.current{
				font-weight: bold;
				cursor: default;
				pointer-events: none;
			}
		}
	}
	
	
	

	
	
}


// SEARCH BAR
.search-bar-wrapper{
	background-color: $color-primary;
	padding: 10px 0px;
	
	&,
	a{
		color: #fff;
	}
	
	a:hover{
		color: $color-grey-dark;
	}
	
	.container{
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	
	.top-address{
		//@include col($m:9);
		//@include col($m:12);
		padding: 0px 15px;
		
		text-align: center;
		
		a{
			margin-right: 15px;
		}
		
		a:last-child{
			margin-right: none;
		}
		
		i{
			margin-right: 10px;
		}
		
	}
	
	.search-form-wrapper{
		@include col($m:3);
		padding: 0px 15px;
	}
	
	form.search-simple{
		text-align: right;
		
		.serch-field{
			position: relative;
			
			input[type="text"]{
				border: none;
				border-radius: 20px;
				padding: 6px 15px;
				width: 220px;
				font-family: "Lato", sans-serif;
				background-color: lighten($color-primary, 55%);
				font-size: 0.9rem;
				margin: 0;
				
				&::placeholder{
					color: $color-primary;
				}
				
				&:focus{
					width: 100%;
					//box-shadow: inset 0px 0px 7px 1px red;
					background-color: lighten($color-primary, 96%);
				}
			}
			
			button{
				//display: none;
				color: #fff;
				padding: 0;
				position: absolute;
				top: 50%;
				right: 8px;
				transform: translateY(-50%);
				
				border: none;
				color: $color-primary;
				
				span{
					display: none;
				}
				
				&:hover{
					background-color: transparent;
				}
				
			}
		}
		

	}
	
	
	// download file ---------------------------------------------~@
	.download-catalog-link{
		margin: 0;
		margin-right: 15px;
		display: none;
		
		i{
			margin-right: 8px;
		}
		
		@include breakpoint{
			display: block;
		}
		
	}

}


