.flexslider{
	position: relative;
	overflow: hidden;
	margin: 0;
	padding-bottom: 60px;
	background: transparent;
	
	&:after{
		content: "";
		display: block;
		background-image: url("../img/mask-slider.svg");
		background-position: bottom center;
		background-repeat: no-repeat;
		position: absolute;
		top:0;
		right: -2px;
		bottom: 55px;
		left: -2px;
		z-index: 3;
		pointer-events: none;
		border-bottom: 2px solid #fff;
	}
	
	ul.slides{
		.slide{
			position: relative;
		}
		
		.slide-message-container-wrapper{
			position: absolute;
			top: 30%;
			left: 0;
			right: 0;
			transform: translateY(-30%);
			text-align: center;
			
			.slide-message-container{
				margin: auto;
				max-width: 600px;
				
				h1,h2,h3,h4{
					text-transform: uppercase;
					font-size: 2.6rem;
					line-height: 1.3;
					margin-bottom: 15px;
				}
				
				h1,h2,h3,h4,p{
					color: #fff
				}
				
				p{
					font-size: 1.4rem;
					//line-height: 1.3;
				}
				
				a{
					margin-top: 15px;
					color: #fff;
					text-transform: uppercase;
					display: inline-block;
					font-size: 1.6rem;
					border-bottom: 3px solid #fff;
					transition: $transition;
					
					&:hover{
						transform: scale(1.2);
					}
				}
			}
		}
	}
	
	ol.flex-control-nav{
		bottom: 0px;
		text-align: center;
		z-index: 2;
	}
	
	.flex-direction-nav li{
		margin: 0;
	}
}