.news-detail-wrapper{
	//display: flex;
	//flex-direction: column;
	margin: auto;
	
	.page-title{
		@include col($m:8);
		@include offset($m:2);
		//text-align: center;
	}
	
	.description-product-wrapper{
		position: static;
		margin: 30px 15px;
	}
	
	//.images-news-wrapper{
	//	padding-bottom: 0px;
	//	margin: 30px 15px 15px;
	//}
	
	.flexslider-news{
		@include col($m:10);
		margin: auto;
		padding-bottom: 0;
		
		&:after{
			content: none;
		}
		
		ol.flex-control-thumbs{
			//@include col($m:10);
			margin: 15px auto 0;
			text-align: right;

			li{
				display: inline-block;
				float: none;
				max-width: 60px;
				margin-left: 15px;
				border: 2px solid #fff;
				box-shadow: $shadow-default;
			}
		}
	}
	
	.description-product-wrapper{
		@include col($m:8);
		@include offset($m:2);
	}
	
	
}


// ----------------------------------------------------------
// :: flexslider
// ----------------------------------------------------------

//.news-detail-wrapper .flexslider-news ul.slides .slide .slide_image{
//	position: relative !important;
//	width: 100% !important;
//	margin-left: auto !important;
//	left: 0 !important;
//	top: 0 !important;
//	margin-right: auto !important;
//	display: block;
//	height: 50vh;
//	-webkit-transform: none !important;
//	-ms-transform:none !important;
//	transform: none !important;
//
//}
//
