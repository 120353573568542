body.white-footer footer.main-footer:after {
	background-image: url("../img/mask-footer-white.svg");
}

.page-title.center{
	text-align: center;
	margin: 30px 0;
}

body.catalog-page{
	.page-title{
		text-align: center;
		margin: 30px 0;
	}
	
	.img-cataleg{
		@include col($m:8);
		margin: auto;
		
		img{
			width: 100%;
			height: auto;
		}
	}
	
	.download-cataleg-wrapper{
		width: 100%;
		text-align: center;
		margin: 30px 0;
	}
	
}