.job-offer-collection{
	width: 100%;
	padding: 0;
	@include breakpoint{
		padding: 15px;
		margin-top: 45px;
	}
}

.no-jobs-available{
	box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.4);
	padding: 60px 15px;
	text-align: center;
	font-size: 1.1rem;
	
	@include breakpoint{
		padding: 90px 15px;
		font-size: 1.2rem;
	}
}
.job-offer-item{
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 15px;
	box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.4);
	@include breakpoint{
		margin-bottom: 30px;
	}
	
	&_description{
		padding: 15px;
		width: 100%;
		@include screen-s{
			width: 60%;
			padding: 30px;
		}
		@include breakpoint{
			width: 75%;
		}
	}
	
	&_image{
		width: 100%;
		@include screen-s{
			width: 40%;
		}
		@include breakpoint{
			width: 25%;
		}
		img{
			display: block;
			width: 100%;
			height: auto;
		}
		
	}
	
	&_summary{
		margin-bottom: 30px;
	}
	
	&_published_value{
		font-weight: bold;
	}
	
	
	.button{
		text-transform: uppercase;
	}
	
}



// ----------------------------------------------------------
// :: job offer detail
// ----------------------------------------------------------


.job-offer-detail{
	width: 100%;
	max-width: 960px;
	margin: auto;
	
	&_title.page-title{
		padding: 0 0 15px;
		margin-left: 0;
	}
	
	&_content{
		margin-bottom: 30px;
	}
	
	&_image{margin-bottom: 30px;
		img{
			width: 100%;
			height: auto;
			display: block;
		}
	}
	
	
	&_form{
		background-color: $color-grey-light;
		padding: 15px;
		margin-bottom: 30px;
		@include breakpoint{
			padding: 30px;
			margin-bottom: 60px;
		}
	}
	
	&_form-title{
		text-transform: uppercase;
		margin-bottom: 25px;
	}
	
	
	&_form{
		
		.form-field{
			margin-bottom: 10px;
		}
		
		label{
			display: block;
			margin-top: 10px;
		}
		
		.acceptance-text{
			display: inline-block;
			margin-left: 5px;
		}
	}
	
}

// ----------------------------------------------------------
// :: form button
// ----------------------------------------------------------

#frontend_common_bundle_contact_type_submit{
	display: block;
	width: 100%;
	margin-top: 30px;
}

.form-message{
	background-color: #fff;
	text-align: center;
	padding: 30px;
	margin-bottom: 30px;
	text-transform: uppercase;
	
	&.success{
		color: #7fca4f;
		border: 1px solid #7fca4f;
	}
	
	&.error{
		color: #ca3636;
		border: 1px solid #ca3636;
	}
}

