.breadcrumb{
	@include flex-container();
	ol{
		width: 100%;
		display: block;
		padding: 10px 0 0;
		margin: 0 0 15px;
		font-size: 0.6rem;
		text-transform: none;
		
		@include breakpoint {
			padding: 15px 15px 0;
			font-size: 0.9rem;
			margin: 0 0 30px;
		}
		
		&:after{
			content: '';
			margin-top: 5px;
			display: block;
			width: 100%;
			height: 1px;
			background-color: transparent;
			box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.1);
			@include breakpoint{
				margin-top: 12px;
			}
		}
		
		li{
			list-style: none;
			display: inline;
			
			&:after{
				content: " / "
			}
			
			&:last-child:after{
				display: none;
			}
			
			a,
			.current-page{
				margin: 0 2px;
				@include breakpoint{
					margin: 0 10px;
				}
			}
			
			a{
				color: $font-color-default;
				&:hover{
					color: $color-primary;
				}
			}
			
			.current-page{
				color: $color-primary;
			}

		}
		
		li:first-child a{
			margin-left: 0;
		}
		
	}
	
	.item-search{
		&:after{
			content: " + "
		}
		
		&:last-child:after{
			content: none;
		}
	}
}
