.list-certifications{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	text-align: center;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 90px;
	
	
	.item-certification{
		@include col($m:4);
		
		figure{
			padding: 30px;
		}
		
		img{
			width: 100%;
			height: auto;
			max-width: 260px;
		}
		
		figcaption{
			margin-top: 10px;
			display: block;
			width: 100%;
			text-align: center;
		}
		
		
	}
}
