.login-download{
	
	.login-download-inner{
		padding: 15px 0 20px;
		
		@include breakpoint{
			padding: 30px;
		}
	}
	
	.login-title{
		text-transform: uppercase;
		font-size: 1.2rem;
		font-weight: bold;
		text-align: center;
		color: $font-color-default;
		letter-spacing: 0.05rem;
		margin-bottom: 15px;
	}
	
	.form-input{
		display: block;
		width: 100%;
		margin: 12px 0;
		text-align: center;
	}
	
	.form-input[type="submit"],
	.button.form-input{
		text-transform: uppercase;
	}
	
	
	.register{
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid rgba(0,0,0,0.2);
	}
	
}

.remember-password{
	display: block;
	margin-top: 15px;
	font-style: italic;
	border: none;
	width: 100%;
	text-align: center;
	padding: 0;

	&:hover{
		border: none;
		background-color: transparent;
		padding: 0;
		color: $link-color-hover;
	}
}
