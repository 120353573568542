body.about{
	
	main.content{
		@include col($m:10, $padding: false);
		margin: auto;
		margin-top: 60px;
		
		
		.content-block{
			@include flex-row();
			margin-bottom: 30px;
			
			// vertical align
			align-items: center;
			justify-content: center;
			
			.illustration{
				@include col($m:6);
			}
			
			.wrapper-text{
				@include col($m:6);
			}
			
			
			.wrapper-text{
				p{
					text-align: justify;
				}
			}
			

			
			
		}
		
	}
}

// galleries

.illustration{
	img{
		@include img-responsive;
	}
	
	figcaption{
		font-size: 0.8rem;
		display: block;
		text-align: center;
	}
}

.illustration.slider{
	li{
		list-style: none;
	}
}

.illustration.gallery{
	margin-left: -7px;
	margin-right: -7px;
	ul{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		
		li{
			list-style: none;
			width: 50%;
			margin: 0;
			padding: 5px 7px;
		}
	}

}