.page-content{
	width: 100%;
	max-width: 960px;
	margin: auto;
	padding: 15px 0;
	text-align: justify;
	
	@include breakpoint{
		padding: 15px;
	}
	
	.page-title{
		margin-left: 0;
		margin-bottom: 15px;
	}
}
