.page-content.error{
	text-align: center;
}

.error-page-title{
	font-size: 8rem;
	font-weight: bold;
	line-height: 1;
	margin-bottom: 25px;
	margin-top: 30px;
}

.error-page-subtitle{
	font-size: 1.6rem;
	margin-bottom: 30px;
}

.error-text{
	margin-bottom: 30px;
}

