fieldset {
	// reset fieldset
	border: 0;
	background-color: $form-fieldset-bg-color;
	border: $form-fieldset-border;
	margin: 0;
	padding: 30px;
	position: relative;
}

fieldset legend {
	text-transform: uppercase;
	float: left;
	width: 100%;
	color: $font-color-headers;
	margin-bottom: 10px;
}

input[type='text'],
input[type='password'],
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='week'],
textarea,
keygen,
datalist,
output,
select {
	@include transition(all .3s ease-in-out);
	background-color: $form-input-bg-color;
	font-family: $form-font-family;
	font-size: $form-font-size;
	color: $form-font-color;
	outline: none;
	padding: $form-input-padding;
	margin: 5px 1px 3px 0;
	border: $form-input-border-size solid $form-input-border-color;
	box-shadow: $form-input-shadow;
	border-radius: $form-input-border-radius;
	
	width: 100%;
	
	&:focus {
		box-shadow: $form-input-focus-shadow;
		border: $form-input-border-size solid $form-input-focus-border-color;
	}
	
	// element disable
	&:disabled {
		color: lighten($form-font-color, 50%);
		//box-shadow: 0 0 5px lighten($form-input-border-color, 20%) inset;
		border: $form-input-border-size solid lighten($form-input-border-color, 5%);
	}
	
	//
	&::placeholder {
		color: $form-placeholder-color;
		font-style: italic;
	}
}

textarea {
	min-height: 140px;
}



select {
	//height: 40px;
	background-color: #F8F8F8;
	appearance: none;
	border-radius: $form-input-border-radius;
	
	&::-ms-expand {
		display: none;
	}
}

.select-container {
	position: relative;
	display: inline;
	
	&:after {
		content: "";
		width: 0;
		height: 0;
		position: absolute;
		pointer-events: none;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
	}
	
	&:after {
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 8px solid black;
		opacity: 0.5;
	}
}





// buttons
input[type='button'],
input[type='submit'],
input[type='reset'],
button {
	@include button;
	
	// active button
	&:active {
		//box-shadow: inset 0 0 3px $form-error-color;
		//background-color: $form-error-color;
	}
	
	&:disabled {
		cursor: default;
		color: lighten($button-color, 30%);
		background-color: lighten($button-bg, 30%);
		border: $form-input-border-size solid lighten($button-border-color, 30%);
		
		&:hover {
			color: lighten($button-color, 30%);
			background-color: lighten($button-bg, 30%);
			border: $form-input-border-size solid lighten($button-border-color, 30%);
		}
	}
	
	&:focus {
		outline: none;
	}
}

// classes form forms

.form-field {
	margin: 15px 0px 30px;
	
	label {
		text-transform: uppercase;
		font-size: 0.8rem;
	}
}

.required {
	color: $form-error-color;
}

