.web-summary{
	
	.summary-content{
		@include col($m:6);
		margin: auto;
		
	}
	
	.summary-points{
		margin-top: 60px;
		@include flex-row();
		
		
		.summary-item{
			@include col($xs:6, $m:3);
			
			figure{
				//@include aspect-ratio(1,1);
				text-align: center;
				padding: 30px;
				
				img{
					width: 100%;
					height: auto;
					border-radius: 50%;
					margin-bottom: 30px;
				}
				
				figcaption{
					font-size: 0.9rem;
					color: $color-primary;
				}
			}
		}
	}
	
	
}

.parent-categories{
	background-color: $color-grey-light;
}

.parent-categories-wrapper{
	width: 100%;
	margin-top: 60px;
	//display: flex;
	@include flex-row();
	align-items: center;
	justify-content: center;
}




.list-new-products-wrapper{
	margin-top: 30px;
	@include flex-row();
	
	.new-product-item-wrapper{
		@include col($m:6);
		
		a{
			color: $font-color-default;
			display: block;
			box-shadow: $shadow-default;
			@include hover-effect;
			@include flex-row();

			
			
			figure.image-wrapper{
				width: 40%;
				height: 0;
				padding-bottom: 40%;
				position: relative;
				overflow: hidden;
				
				img{
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					height: 100%;
					z-index: -1;
				}
			}
			
			.summary-product {
				width: 60%;
				padding: 25px;
				background-color: $color-grey-light;
				
				h3.product-title {
					text-transform: uppercase;
					font-size: 1rem;
					color: $font-color-default;
					font-weight: normal;
				}
			}
			
			
			&:hover{
				transform: scale(1.05);
			}
		}
	}
}


.download-catalog,
.new-products{
	position: relative;
}

.anchor-link{
	position: absolute;
	top: -50px;
	display: block;
	width: 10px;
	height: 10px;
	background-color: red;
}