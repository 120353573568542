// MASK

footer.main-footer{
	position: relative;
	overflow: hidden;
	
	&:after{
		content: "";
		display: block;
		background-image: url("../img/mask-footer.svg");
		background-position: top center;
		background-repeat: no-repeat;
		position: absolute;
		top:0;
		right: -2px;
		bottom: 0;
		left: -2px;
		z-index: 3;
		pointer-events: none;
		border-bottom: 2px solid #fff;
	}
}


footer.main-footer{
	background-color: $color-grey;
	padding-top: 90px;
	color: #fff;
	
	h1,h2, h3,h4,a{
		color: #fff;
	}
	
	.title-block{
		font-size: 1rem;
		font-weight: normal;
		letter-spacing: 0.05rem;
		margin-top: 10px;
	}
	
	.brand-footer-container{
		width: 100%;
		img{
			display: block;
			margin: 30px auto;
			max-width: 140px;
		}
	}
	
	.footer-contact{
		@include flex-container();
		padding-bottom: 60px;
		
		.block{
			text-align: center;
			@include col($m:4);
			color: #fff;
			
			.icon-block{
				display: block;
				max-width: 35px;
				margin: 15px auto 25px;
			}
		}
		
		address{
			font-style: normal;
		}
	}
}




//

.footer-legal{
	background-color: $color-primary;
	padding: 15px;
	text-align: center;
	
	.container{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		
		@include breakpoint{
			flex-direction: row;
		}
	}
	
	// gerela font-size and color
	&,
	a{
		font-size: 0.8rem;
	}
	
	a:hover{
		color: $color-grey-dark;
	}
	
	.horizontal-menu,
	.copyright{
		text-align: center;
		//@include col($m:6, $padding: false);
		padding: 0 15px;
		
	}
	
	
	.horizontal-menu{
		ul{
			//@include list-hor;
			margin: 0;
		}
		
		li{
			display: inline;
		}
		
		li:after{
			content: " • ";
			display: inline;
			color: #fff;
			margin: 0 8px;
		}
		
		li:last-child:after{
			display: none;
		}
		
		@include breakpoint{
			text-align: left;
		}
		
	}
	
	.copyright{
		//@include flex-row;
		//margin: auto;
		display: flex;
		align-items: center;
		
		label{
			font-size: 0.6rem;
			display: inline;
		}
		
		svg{
			margin-left: 10px;
			width: 90px;
		}
		
		@include breakpoint{
			text-align: right;
		}
	}
}


#perception-link{
	
	img{
		margin-left: 5px;
		width: 100px;
		height: auto;
	}
	
	svg{
		.logo-letter,
		.logo-icon{
			fill:#fff;
		}
		
		.logo-icon{
			fill-rule:evenodd;
		}
		
		&:hover{
			.logo-letter,
			.logo-icon{
				fill:$color-grey-dark;
			}
		}
	}
}


