@charset 'UTF-8';

//necessari per poder utilitzar accentes i caràcters especials

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  background-color: $table-bg-color;

  caption {
    font-weight: bold;
  }

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: $table-align;
    border-top: $table-border-width solid $table-border-color;
    border-bottom: $table-border-width solid $table-border-color;
    text-align: left;
  }
}
