@charset 'UTF-8';


// insert content on html for javascript comunication
html {
  &:after {
    content: '{"gutter":"' + $gutter-hor + '","grid":' + $grid-default + ',"containerWidth":"' + $container-width + '"}';
    display: none;
  }
}

#senseGrid-button{
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: red;
  z-index: 100000000;
  border: none;
  border-radius: 0;
  padding: 5px 10px;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #fff;
  pointer-events: visible;
  
  &:before{
    content: 'Toggle Grid';
  }
}

.show-grid-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  //background-color: #ccc;
  pointer-events: none;
  z-index: 10000000;
}



.show-grid-container {
  @include flex-container(false);
  height: 100%;
  opacity: .15;
  //position: absolute;

  .show-col {
    width: percentage(1/12);
    background-color: $color-grid;
    border-left: $gutter-hor solid $color-grid-padding;
    border-right: $gutter-hor solid $color-grid-padding;
    box-sizing: border-box;
    text-align: center;
    font-size: .6rem;
  }
}

body.show-media-queries {
  &::before {
    content: 'screen-xxs';
    position: fixed;
    padding: 5px 7px;
    bottom: 0;
    left: 0;
    font-size: .8rem;
    z-index: 2000;
    pointer-events: none;
    color: #fff;
    background-color: #204db1;


    @include screen-xs {
      content: 'screen-xs: ' + $screen-xs;
      background-color: #2097b1;
    }

    @include screen-s {
      content: 'screen-s: ' + $screen-s;
      background-color: #01e85e;
    }
    @include screen-m {
      content: 'screen-m: ' + $screen-m;
      background-color: #9bfb74;
    }
    @include screen-l {
      content: 'screen-l: ' + $screen-l;
      background-color: #d0b25a;
    }

    @include screen-xl {
      content: 'screen-xl: ' + $screen-xl;
      background-color: #d43e3e;
    }
  }
}

.show-grid-wrapper {
  .show-grid-container{
    visibility: hidden;
  }
  
  &.visible .show-grid-container{
    visibility: visible;
  }
}