.download-catalog,
.newsletter-register{
	padding: 75px 0 30px;
	
	.newsletter-form-wrapper{
		@include col($m:4);
		margin: auto;
		text-align: center;
		
		.newsletter-title{
			text-transform: uppercase;
			color: $color-primary;
			margin-bottom: 30px;
			font-weight: normal;
			
			.icon{
				display: block;
				font-size: 4rem;
				margin-bottom: 15px;
			}
		}
		.form-explanation{
			margin-bottom: 30px;
			font-size: 0.9rem;
		}
		
		input, button{
			margin: 0;
			text-align: center;
		}
		
		.form-field{
			margin: 15px 30px 20px;
		}
		
		button{
			text-transform: uppercase;
			display: block;
			width: 100%;
		}
	}
}

.download-catalog .button{
	margin-top: 30px;
}