

// ----------------------------------------------------------
// ::
// ----------------------------------------------------------
.request-access .page-title{
	text-align: center;
	margin-bottom: 15px;
}

.request-text{
	max-width: 680px;
	margin: 0 auto 45px;
	text-align: center;
}

.required-text-help{
	font-style: italic;
	font-size: 0.8rem;
	margin-bottom: 0;
	text-align: center;
}

// ----------------------------------------------------------
// ::
// ----------------------------------------------------------
.register-form{
	width: 100%;
	margin-top: 30px;
	margin-bottom: 60px;
	padding: 10px;
	background-color: #f2f2f2;
	
	.inner-register-form{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	
	.col{
		@include col($m:6);
		padding-bottom: 0;
		padding-top: 0;
		
		@include breakpoint{
			padding-top: 15px;
		}
	}
	
	.col-full{
		width: 100%;
		padding: 15px 15px 0;
	}
	
	
	
	.form-field{
		margin-bottom: 0;
		margin-top: 10px;
		
		@include breakpoint{
			margin-bottom: 10px;
			margin-top: 15px;
		}
	}
	
	.form-field.col-full{
		margin-top: 0;
	}
	
	label.privacy-policy{
		display: block;
		margin-bottom: 10px;
		
		strong{
			font-weight: normal;
		}
	}
	
	.privacy-policy-text{
		font-size: 0.8rem;
		margin-top: 10px;
		text-align: justify;
	}
	
	label.required{
		color: $font-color-default;
	}
	
	select{
		background-color: #fff;
	}
	
}

.required-tag{
	color: #bf0101;
}


.submit-wrapper{
	button{
		display: block;
		width: 100%;
		text-transform: uppercase;
		margin-bottom: 15px;
	}
}
