@mixin sm-sub-items-indentation($amount, $chainable: 'ul ', $level: 4, $chain: '') {
	@for $i from 1 through $level {
		$chain: $chain + $chainable;
		#{$chain} a,
		#{$chain} a:hover,
		#{$chain} a:focus,
		#{$chain} a:active {
			border-left: ($amount * ($i + 1)) solid transparent;
		}
	}
}

// Items text indentation for deeper levels
$submenu-indentation: 8px !default;

// COLORS
$menu-bg: #fff;
$menu-color: $color-primary;

$menu-bg-hover: $color-primary;
$menu-color-hover: #fff;

$submenu-bg-color: lighten($color-primary, 60%);

// border separator
$border-separator: 1px solid $color-primary;

// PADDINGS
$padding-menu-principal: 20px 20px;
$padding-submenu: 10px 20px;


// GENERAL STYLE
.sm {
	
	// Main menu items
	a {
		padding: $padding-menu-principal;
		display: block;
		background-color: $menu-bg;
		border-bottom: $border-separator;
		
		&:hover{
			color: $menu-color-hover;
			background-color: $menu-bg-hover;
		}
		
		&.current {
		
		}
		
	}
	
	span.sub-arrow {
		display: none;
	}
	
	// arrow
	a:not(:only-child){
		&:after{
			width: 10px;
			text-align: right;
			content: '+';
			display: inline-block;
			float: right;
			margin-left: 4px;
		}
		
		&.highlighted:after{
			content: '-';
		}
	}
	
	
	
	
	// Main menu items separators
	// Sub menus box
	ul {
		
		// Sub menus items
		a {
			&,
			&:hover,
			&:focus,
			&:active {
				//font-size: $sm-font-size-small;
				// add indentation for sub menus text
				//border-left: $sm-collapsible-sub-item-indentation solid transparent;
			}
		}
		
		// Add indentation for sub menus text for deeper levels
		@include sm-sub-items-indentation($submenu-indentation);
	}
}


// DESKTOP MODE

@include breakpoint {
	
	.sm{
		text-align: right;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		
		
		// PARENT MENU
		> li {
			display: inline-block;
			
			> a{
				border-bottom: none;
				font-size: 0.9rem;
			}
		}
		
		li{
			position: relative;
			//z-index: 100;
		}
		
		a.highlighted{
			position: relative;
			z-index: 100;
			background-color: $submenu-bg-color;
			color: $color-primary;
		}
		
		// SUBMENU
		ul {
			// rule for childs
			position: absolute;
			width: 12rem;
			box-shadow: 0px 2px 1px 0px rgba(#000, 0.4);
			
			a {
				font-size: 0.8rem;
				padding: 15px 15px;
				background-color: $submenu-bg-color;
				border-left: none !important;
				
				.sub-arrow {
					right: 20px;
				}
			}
			
			// 2o subnivel
			ul {
			}
		}
	}
	
	
	// Main menu box
	.sm {
		
		// SUB MENUS
		
		// Scrolling arrows containers for tall sub menus - test sub menu: "Sub test" -> "more..." in the default download package
		.scroll-up,
		.scroll-down {
			position: absolute;
			display: none;
			visibility: hidden;
			overflow: hidden;
			height: 20px;
			// width and position will be set automatically by the script
		}
		.scroll-up-arrow,
		.scroll-down-arrow {
			position: absolute;
			top: 6px;
			left: 50%;
			margin-left: -8px;
			// we will use one-side border to create a triangle so that we don't use a real background image, of course, you can use a real image if you like too
			width: 0;
			height: 0;
			overflow: hidden;
			border-width: 0 6px 8px 6px; // tweak size of the arrow
			border-style: dashed dashed solid dashed;
		}
		.scroll-down-arrow {
			border-width: 8px 6px 0 6px;
			border-style: solid dashed dashed dashed;
		}
		
		// Rigth-to-left
		
		// Main menu box
		
		// Vertical main menu
		
		// Main menu box
		//&.sm-vertical {
		//	border-bottom: 0;
		//
		//	// Main menu items
		//	a {
		//		padding: $sm-desktop-vertical-item-padding-vertical $sm-desktop-vertical-item-padding-horizontal;
		//
		//		&:hover,
		//		&:focus,
		//		&:active,
		//		&.highlighted {
		//			box-shadow: none;
		//		}
		//
		//		// Sub menu indicators
		//		.sub-arrow {
		//			right: 10px;
		//			border-style: dashed dashed dashed solid;
		//		}
		//	}
		//
		//	// Sub menus box
		//	ul {
		//		// Sub menus items
		//		a {
		//			padding: $sm-desktop-sub-item-padding-vertical $sm-desktop-sub-item-padding-horizontal;
		//		}
		//	}
		//}
	}
}