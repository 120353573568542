// sample style using sensegrid
@import "variables";
@import "vendor/sensegrid/sensegrid";

// project mixins
@import "mixins";

// icons
@import "vendor/icomoon/icomoon";

//smartmenu, hamburguer, navigation
@import "vendor/smartmenu/sm-core";
@import "vendor/smartmenu/sm-custom";
@import "vendor/hamburgers/hamburgers";

//jquery modal
@import "vendor/jquery-modal/jquery.modal";

//flexslider
@import "vendor/flexslider/flexslider-core";

// logo
@import "partials/logo";

@import "partials/navigation";

@import "partials/banner";

@import "partials/breadcrumb";

@import "partials/pagination";

//partials
@import "partials/header";
@import "partials/footer";

//modules
@import "partials/search-forms";

//pages
@import "partials/home";
@import "partials/product-list";
@import "partials/product-detail";

@import "partials/news-list";
@import "partials/news-detail";

@import "partials/contact";
@import "partials/about";

@import "partials/documentation";



//
@import "partials/newsletter-form";

@import "partials/page-catalog";
@import "partials/page-gallery";
@import "partials/page-certifications";

//
@import "partials/login-form";

@import "pages/pages";
@import "pages/error";
@import "pages/request-access";

@import "pages/job-offers";


//BASE
figure{
	margin: 0;
}


//CLASSES
.container {
	@include flex-container;
}

.page-title{
	margin: 0 15px 0;
	font-weight: normal;
	text-transform: uppercase;
	width: 100%;
}

.page-sidebar .page-title{
	margin: 0;
	width: 100%;
	padding: 0 15px;
}

.title-item{
	text-transform: uppercase;
	font-size: 1rem;
	font-weight: normal;
	text-align: center;
	margin-top: 25px;
}

.title-section,
.section-title{
	display: block;
	width: 100%;
	padding: 15px 15px 0;
	font-weight: normal;
	text-transform: uppercase;
	font-size: 1.4rem;
	text-align: center;
}

.title-section{
	color: $font-color-default
}

.link-wrapper{
	margin-top: 45px;
	text-align: center;
	width: 100%;
}

.link-featured{
	font-size: 1.2rem;
	text-transform: uppercase;
	display: inline-block;
	padding: 6px 2px;
	border-bottom: 4px solid $color-primary;
	transition: $transition;
	
	&:hover{
		color: $color-primary;
		transform: scale(1.2);
	}
}

.summary-title{
	font-weight: normal;
	font-size: 1.1rem;
	text-transform: uppercase;
	color: $font-color-default;
}

.summary-text {
	font-size: 0.85rem;
}

.section-block{
	padding: 60px 0;
}


.image-wrapper{
	img{
		width: 100%;
		height: auto;
	}
}

.list-items{
	margin-top: 45px;
}

.last-section{
	background-color: $color-grey-light;
}


.download-datasheet.button{
	margin-top: 15px;
	i{
		margin-right: 8px;
	}
}


.block-sidebar{
	margin-bottom: 30px;
	
	.title-block{
		text-transform: uppercase;
		font-size: 1.2rem;
	}
}

// SECTIONS
section.main-content{
	padding-bottom: 30px;
}


//SECTIONS
section.last-news,
section.related-product,
section.separator{
	
	.title-section{
		@include separator-top();
		margin: 0px 0 45px;
		
		&:before{
			margin-bottom: 45px;
		}
	}
}

// banner

.top-banner{
	position: relative;
	overflow: hidden;
	
	figure{
		@include aspect-ratio(15,2);
	}
	
	img{
		@include img-responsive;
	}
	
	.page-title{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
		font-weight: bold;
		color: #fff;
		font-size: 3rem;
		letter-spacing: 0.1rem;
		text-shadow: $text-shadow-default;
	}
}


// Layouts

.page-sidebar{
	@include flex-row;
	
	
	.left-sidebar{
		@include col($m:3);
		padding-top: 0;
		
		.page-title{
			padding: 0;
			margin-bottom: 30px;
		}
	}
	
	main.content{
		@include offset($m:1);
		@include col($m:8);
		
		margin-bottom: 60px;
		padding-top: 0;
	}
	
}

// base

h1,h2,h3,h4{
	font-weight: normal;
	margin-top: 0;
}

fieldset{
	border: none;
}


// ----------------------------------------------------------
// :: Hide elements
// ----------------------------------------------------------

.newsletter-register{
	display: none;
}

.slide-message-container-wrapper .slide-message-container{
	display: none;
}


// ----------------------------------------------------------
// :: IExplorer
// ----------------------------------------------------------

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	footer.main-footer:after,
	.flexslider:after{
		display: none;
	}
}
