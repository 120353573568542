@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?w4i14n');
  src:  url('../fonts/icomoon/icomoon.eot?w4i14n#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?w4i14n') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?w4i14n') format('woff'),
    url('../fonts/icomoon/icomoon.svg?w4i14n#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\f053";
}
.icon-arrow-right:before {
  content: "\f054";
}

.icon-mail-alt:before,
.icon-send:before {
  content: "\e900";
}
.icon-map:before {
  content: "\e901";
}
.icon-location:before {
  content: "\e902";
}
.icon-facebook-squared:before,
.icon-facebook:before {
  content: "\e903";
}
.icon-twitter-squared:before,
.icon-twitter:before {
  content: "\e904";
}
.icon-phone:before,
.icon-telephone:before {
  content: "\e905";
}
.icon-contact:before {
  content: "\e906";
}
.icon-user_1:before {
  content: "\e907";
}
.icon-open:before {
  content: "\e908";
}
.icon-upload:before {
  content: "\e909";
}
.icon-plus:before {
  content: "\e90a";
}
.icon-minus:before {
  content: "\e90b";
}
.icon-downloadDocument:before {
  content: "\e90c";
}
.icon-file-pdf:before,
.icon-document_1:before {
  content: "\e90d";
}
.icon-addCart:before {
  content: "\e90e";
}
.icon-cart:before {
  content: "\e90f";
}
.icon-user:before {
  content: "\e910";
}
.icon-document:before {
  content: "\e911";
}
.icon-download:before {
  content: "\e912";
}
.icon-search:before,
.icon-zoom:before {
  content: "\e913";
}
.icon-see:before {
  content: "\e914";
}
.icon-featured:before {
  content: "\e915";
}
.icon-new:before {
  content: "\e916";
}
.icon-home:before {
  content: "\e917";
}
.icon-image:before {
  content: "\e918";
}
.icon-clock:before {
  content: "\e94e";
}
.icon-instagram:before {
  content: "\ea92";
}
