.product-detail-wrapper{
	@include flex-row;
	
	.title-page{
		width: 100%;
		padding: 15px;
	}
	
	.images-product-wrapper{
		@include col($m:6, $padding: false);
	}
	
	.description-product-wrapper{
		@include col($m:6);
		padding: 0;
		
		@include breakpoint{
			padding: 15px;
		}
	}
	
	
	.images-product-wrapper{
		figure{
			margin: 0;
			
			img{
				width: 100%;
				height: auto;
			}
		}
	}
	
	.description-product-wrapper{
		
		.reference{
			margin-bottom: 15px;
			font-size: 1.4rem;
			label{
				text-transform: uppercase;
				color: $font-color-headers;
				font-size: 1.2rem;
			}
		}
		
		.description{
			h2,h3,h4{
				text-transform: uppercase;
				font-weight: normal;
			}
			
			h3{
				font-size: 1.1rem;
			}
		}
		
		.download{
			margin-top: 30px;
			i{
				margin-right: 5px;
			}
		}
	}
}
// ----------------------------------------------------------
// ::
// ----------------------------------------------------------

.title-product{
	font-size: 1.8rem;
}

.mobile-title{
	display: block;
	width: 100%;
	margin: 0 0 15px;
	font-size: 1.4rem;
	
	@include breakpoint{
		display: none;
		margin: 0 15px 15px;
	}
}

.desktop-title{
	display: none;
	@include breakpoint{
		display: block;
	}
}

.product-section-title{
	text-transform: uppercase;
	font-size: 0.9rem;
	//color: $font-color-default;
}



// ----------------------------------------------------------
// ::
// ----------------------------------------------------------
.product-description,
.product-downloads,
.product-variations{
	margin-bottom: 30px;
}

.product-description{
	margin-top: 20px;
}


// ----------------------------------------------------------
// ::
// ----------------------------------------------------------


.product-downloads-list{
	li{
		list-style: none;
		margin: 0 5px 0 0;
		display: inline-block;
	}
	
	.download-button{
		margin: 5px 0;
		text-transform: uppercase;
		font-size: 0.9rem;
		i{
			margin-left: 8px;
		}
	}
}

// ----------------------------------------------------------
// :: product table
// ----------------------------------------------------------

.products-list-table{
	margin-top: 25px;
	
	th{
		text-transform: uppercase;
		font-size: 0.7rem;
		
		@include breakpoint{
			font-size: 0.8rem;
			letter-spacing: 0.05rem;
		}
	}
	
	td{
		font-size: 0.7rem;
		
		@include breakpoint{
			font-size: 0.85rem;
		}
	}
	
	td,
	th{
		text-align: center;
		border-color: #fff;
		border-width: 1px;
		padding: 7px 5px;
		
		@include breakpoint{
			padding: 7px 10px;
		}
	}
	
	
	td:first-child,
	th:first-child{
		text-align: left;
		padding-left: 15px;
	}
	
	td:last-child,
	th:last-child{
		text-align: right;
		padding-right: 15px;
	}
	
	tr:nth-child(even) {
		background: #fbfbfb
	}
	tr:nth-child(odd) {
		background: #F2F2F2
	}
	
	th{
		background-color: #ebebeb;
	}
	
}

// ----------------------------------------------------------
// ::
// ----------------------------------------------------------

.clarification-text{
	margin-top: 15px;
	text-align: center;
	font-style: italic;
}

#login-button {
	display: inline;
	padding: 0;
	border: none;
	font-style: italic;
	text-transform: uppercase;
	
	&:hover{
		background-color: transparent;
		color: $link-color-hover;
	}
}

// ----------------------------------------------------------
// ::
// ----------------------------------------------------------
.flexslider-product{
	margin: 0;
	
	@include breakpoint{
		margin: 15px;
	}
	
	.flex-viewport{
		box-shadow: $shadow-default;
	}
	
	.slides{
		//margin: 15px;
	}
	
	
	.slide{
		img{
			width: 100%;
			height: auto;
		}
	}
	
	
	ol.flex-control-nav{
		@include flex-row();
		//margin: 0 -15px;
		padding: 5px 0px 15px;
		margin-left: -10px;
		
		li{
			//width: 20%;
			@include col($xs: 2, $padding: false);
			border: 10px solid transparent;
			//margin: 10px 15px;
			//padding:15px;
		}
		
		li:first-child{
			//border-left: 0 solid red;
		}
		
		img.flex-active{
			box-shadow: $shadow-default;
		}
	}
}



