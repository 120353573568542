.list-last-news{
	@include flex-row;
	
	.news-item-wrapper{
		@include col($m:4);
		margin-bottom: 15px;
		position: relative;
		
		&:after{
			content: "";
			position: absolute;
			top: 15px;
			left: 15px;
			right: 15px;
			bottom: 15px;
			background-color: #fff;
			z-index: -2;
			box-shadow: $shadow-default;
			transition: all 0.15s ease-in-out;
		}
		
		a{
			
			@include hover-effect;
			
			color: $font-color-default;
			display: flex;
			flex-direction: column;
			
			// change aspect ratio
			figure.image-wrapper{
				padding-bottom: 70%;
			}
			
			.summary-news{
				padding: 15px 20px;
			}
		}
		
		&:hover:after{
			transform: scale(1.05);
		}
		
		
		a:hover{
			//transform: scale(1);
			.summary-title,
			.summary-text{
				color: $color-primary;
			}
			
		}
	}
	
}