
// MIXINS

@mixin hover-effect(){
	
	display: block;
	
	&,
	*,
	*:before,
	*:after{
		transition: all 0.15s ease-in-out;
	}
	
	figure{
		position: relative;
		// proportion
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		
		&:after{
			font-family: "icomoon";
			content: "\e90a";
			color: transparent;
			font-size: 2rem;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		
		&:before{
			content:"";
			text-align: center;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: transparent;
		}
	}
	
	&:hover{
		transform: scale(1.05);
		
		figure{
			
			&:before{
				background-color: rgba($color-primary, 0.3);
			}
			
			&:after{
				color: #fff;
				font-size: 3.5rem;
			}
			
		}
	}
	
}


@mixin separator-bottom(){
	&:after{
		content: '';
		margin-top: 12px;
		display: block;
		width: 100%;
		height: 1px;
		background-color: transparent;
		box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.1);
	}
}

@mixin separator-top(){
	&:before{
		content: '';
		margin-bottom: 15px;
		display: block;
		height: 1px;
		background-color: transparent;
		box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.1);
	}
}