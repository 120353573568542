// responsive behavior

// burger hide on desktop view
.hamburger-container{
	@include breakpoint{
		display: none;
	}
}

// main-menu-wrapper hide on mobile, and show on desktop view
nav.main-menu-wrapper{
	display: none;
	
	@include breakpoint{
		display: block;
	}
}


.main-navigation-wrapper{
	
	.container{
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	
	.logo-container{
		@include col($xs:1, $padding: false);
		padding: 0 15px;
	}
	
	.hamburger-container{
		@include col($xs:11, $padding: false);
		text-align: right;
	}
	
	.main-menu-wrapper{
		@include col($m:11, $padding: false);
	}
}


//
.main-navigation-wrapper{
	background-color: #fff;
	position: relative;
	
	box-shadow: 0px -5px 9px 5px rgba(0,0,0,0);
	z-index: 10;
	
	&,
	.logo-container *{
		transition: all 0.3s linear;
	}
	
	svg.logo{
		width: 60px;
		margin: 10px 0px 5px;
		
		@include breakpoint{
			width: 105px;
		}
	}
	
	
	
	
	
	// MENU STYLES
	
	#main-menu{
		@include breakpoint{
			margin-right: 15px;
		}
	}
	
	#main-menu a {
		font-size: 0.9rem;
		padding: 15px 25px;
		//background-color: #fff;
		
		&:hover{
		
		}
		
		@include breakpoint{
			font-size: 0.85rem;
			padding: 20px 0 20px 17px;
		}
		
		@include breakpoint{
			padding: 20px 0 20px 15px;
			font-size: 0.8rem;
		}
		
	}
	
	#main-menu > li > a{
		text-transform: uppercase;
		
		@include breakpoint{
			line-height: 90px;
		}
	}
	
	#main-menu {
		li.current > a{
			//background-color: $color-primary;
			//color: #fff;
		}
	}
}


#main-menu.sm{
	
	a{
		color: $font-color-default;
	}
	
	a:hover{
		color: #fff;
		//background-color: transparent;
	}
	
	@include breakpoint{
		a{
			color: $font-color-default;
		}
		
		a:hover{
			color: $color-primary;
			background-color: transparent;
		}
		
		li.current > a{
			color: $color-primary;
			font-weight: bold;
		}
	}
}
	
	
	// WHEN SCROLL OS WORKING
.main-navigation-wrapper.fixed{
	position: fixed;
	box-shadow: 0px -1px 15px 5px rgba(0,0,0,0.4);
	top:0;
	right: 0;
	left:0;
	
	svg.logo{
		width: 40px;
		height: 40px;
	}
	
	#main-menu > li > a{
		line-height: 20px;
	}
	
}


// ICON HOME

a.home{
	&:before{
		content: '\e917';
		font-family: 'icomoon';
		font-size: 0.8rem;
	}
	
	span{
		display: none;
	}
}
