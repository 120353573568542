
// CATEGORIES PAGE

.parent-category{
	@include flex-row;
	
	.title-parent-category{
		width: 100%;
		padding: 15px;
	}
}


.category-item-wrapper{
	@include col($xs:6, $m:4, $l:3, $xl: 2);
	padding: 15px;
	
	.subcategory-item-link{
		display: block;
		
		&,
		*,
		*:before,
		*:after{
			transition: all 0.15s ease-in-out;
		}
		
		.image-wrapper{
			margin: 0;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			border-radius: 50%;
			overflow: hidden;
			position: relative;
			display: block;
			
			&:after{
				font-family: "icomoon";
				content: "\e90a";
				color: transparent;
				font-size: 2rem;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
			}
			
			&:before{
				content:"";
				text-align: center;
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				background-color: transparent;
				z-index: 1;
			}
		}
		
		img{
			@include img-responsive;
		}
	}
	
	.subcategory-item-link:hover{
		transform: scale(1.05);
		
		.image-wrapper{
			
			&:before{
				background-color: rgba($color-primary, 0.3);
			}
			
			&:after{
				color: #fff;
				font-size: 3.5rem;
			}
		}
	}
}

// PRODUCT LIST PAGE

.list-products-wrapper{
	@include flex-row();
	
	.item-product{
		@include col($m:3);
		text-align: center;
		margin-bottom: 30px;
		
		a{
			@include hover-effect();
			
			figure{
				border-radius: 10px;
				overflow: hidden;
				box-shadow: $shadow-default;
			}
		}
		
		img{
			width: 100%;
			height: auto;
		}
	}
}



// SUBFAMILY PRODUCT PAGE

.category-description{
	display: flex;
	align-items: center;
	width: 100%;
	padding-bottom: 15px;
	flex-wrap: wrap;
	
	@include breakpoint{
		margin: -10px 15px 0;
		padding-bottom: 30px;
	}
	

	.category-icon{
		display: inline-block;
		border-radius: 50%;
		overflow: hidden;
		height: 40px;
		width: 40px;
		margin: 0 15px 0 0;
		
		img{
			width: 100%;
			height: auto;
		}
		
		@include breakpoint{
			height: 80px;
			width: 80px;
		}
		
	}
	
	.category-text-wrapper{
		display: inline-block;
		
		.title-category{
			font-size: 1.2rem;
			margin-left: 0;
			line-height: 1;
			
			@include breakpoint{
				font-size: 2rem;
			}
			
		}
	}
	
	&:after{
		content: '';
		margin: 10px 0 0;
		display: block;
		width: 100%;
		height: 1px;
		background-color: transparent;
		box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.1);
		
		@include breakpoint{
			margin: 15px 0 0;
		}
		
	}
}


// search form no result

.no-products{
	width: 100%;
	text-align: center;
	padding: 30px 0 15px;
	font-size: 1.2rem;
	
	@include breakpoint{
		font-size: 1.6rem;
	}
}
