@charset 'UTF-8';

kbd,
code,
samp,
.code {
  font-family: $code-font-family;
  color: $code-color;
}

kbd {
  background-color: $code-kbd-bg-color;
  display: inline-block;
  padding: 0 5px;
  border-radius: 4px;
}

code {
  color: $code-color;
}

pre {
  margin: $text-block-margin;
}
