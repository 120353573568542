body.gallery{
	
	.page-title{
		text-align: center;
		margin: 30px 0;
	}
	
	.instagram-wrapper{
		width: 100%;
	}
	
	.follow-button-wrapper{
		width: 100%;
		text-align: center;
		margin-bottom: 60px;
	}
}