nav.items-pagination{
	
	//values for quickily change
	
	$current-border-radius: 8px;
	$current-color: $font-color-default;
	$current-border-color: lighten($font-color-default, 60%);
	$current-active-bg-color: lighten($color-primary, 40%);
	$current-hover-bg-color: lighten($color-primary, 50%) ;
	
	margin: 30px auto 0;
	padding: 30px 0;
	text-align: center;
	
	ul{
		margin: 0;
		@include flex-row;
		justify-content: center;
		align-items: center;
		width: 100%;
		
		li{
			list-style: none;
			display: block;
			margin: 0;
			
			a{
				font-size: 0.9rem;
				border: 1px solid $current-border-color;
				border-width: 1px 0px 1px 1px;
				padding: 8px 15px;
				display: block;
				color: $current-color;
				
				
				&:hover{
					border-color: $color-primary;
					background-color: $color-primary;
					color: #fff;
				}
				
				
			}
			
			&.disabled a{
				//display: none;
				pointer-events: none;
				color: lighten($font-color-default, 50%);
			}
			
			&.active a{
				background-color: $current-active-bg-color;
				pointer-events: none;
				color: #fff;
			}
			
			&:first-child a{
				border-bottom-left-radius: $current-border-radius;
				border-top-left-radius: $current-border-radius;
			}
			
			&:last-child a{
				border-width: 1px 1px 1px 1px;
				border-bottom-right-radius: $current-border-radius;
				border-top-right-radius: $current-border-radius;
			}
		}
	}
}