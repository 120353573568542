
.contact-form-wrapper{
	margin-top: 15px;
}

.footer-map{
	iframe,
	.googleMaps{
		width: 100%;
		height: 400px;
		margin: 0;
		margin-bottom: -10px;
		padding: 0;
	}
}

.block-sidebar address{
	font-style: normal;
}

.form-summary{
	color: $font-color-headers;
}

.legal-note{
	margin-bottom: 30px;
	p{
		font-size: 0.9rem;
		color: $font-color-headers;
	}
}

body.contact h1.page-title{
	font-size: 1.4rem;
}