@charset 'UTF-8';

// Media queries breakpoints
$screen-xs:  0;           // movil en bootstrap 480px !default;
$screen-s:   544px;       // phablet default 544
$screen-m:   900px;       // tablet default 768
$screen-l:   1140px;       // desktop
$screen-xl:  1280px;      // destop-HD

// navbar-breakpoint
$breakpoint:   900px;

// Grid configuration
$container-width: 1380px;      // max-width of container
$grid-default: 12;             // number of columns
$gutter-hor: 15px;             // space beetwen columns
$gutter-ver: 15px;             // space beetwen rows

// helpers grid
$show-grid: false;
$color-grid: #00ffe8;
$color-grid-padding: #da65d5;

// colors
$color-primary: #c02102;
$color-secondary: #68afc1;
$color-grey: #545454;
$color-grey-light: #f2f2f2;
$color-grey-dark: darken(#545454,20%);

// color fonts
$font-color-default: $color-grey-dark;
$font-color-headers: #b53302;

// colors backgrounds
$bg-color: #fff;

// links
$link-color: $color-primary;
$link-color-hover: $color-secondary;
$link-decoration: none;
$link-decoration-hover: none;

// external fonts
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,900,900i');

// fonts
$font-family-sans-serif: 'Lato', sans-serif !default;
$font-family-serif: Georgia, 'Times New Roman', Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;

$font-default: 'Lato', sans-serif !default;
$font-size-default: 16px;
$line-height-default: 1.5;

// headers
$font-headers-weight: bold;
$font-headers: $font-default;

// espacio entre bloques
$text-block-margin: 0 0 0.5rem 0;




// Animation, graphic styles
$transition: all .15s ease-out;
$border-radius: 12px; // bordes redondeados
$text-shadow-default: 0 1px 3px rgba(0, 0, 0, .25);
$shadow-default: 0px 0px 4px -1px rgba(0, 0, 0, 0.4);

//
// BUTTONS
//
$button-border-color: $color-primary;
$button-color: $color-primary;
$button-bg: transparent;
$button-border-radius: $border-radius;

// button hover
$button-border-color-hover: $color-primary;
$button-color-hover: #fff;
$button-bg-hover: $color-primary;

$button-padding: 10px 15px;
$button-padding-small: 5px 10px;
$button-padding-big: 10px 35px;


//
// FORMS
//

// forms fonts
$form-font-family: $font-default;
$form-font-size: $font-size-default;
$form-font-color: $font-color-default;
$form-placeholder-color: #d8d8d8;

// forms styles
$form-input-bg-color: #fff;
$form-input-border-size: 1px;
$form-input-border-radius: $border-radius;
$form-input-border-color: #ccc;
$form-input-padding: 10px 15px;
$form-input-shadow: 0px 0px 4px -1px rgba(0,0,0,0);

// focus
$form-input-focus-shadow: 0px 0px 4px -1px $color-primary;
$form-input-focus-border-color: $color-primary;

//fieldset
$form-fieldset-bg-color: $color-grey-light;
$form-fieldset-border: 1px solid #ccc;

// validation forms
$form-error-color: #bf0101;
$form-success-color: #49ec67;


//
// MISCELANEOS
//

// lists
$list-margin: 10px 0;
$list-dd-margin: 10px 0 20px;

// tables
$table-border-color: #ccc;
$table-border-width: 1px;
$table-align: top;
$table-justify: left;
$table-cell-padding: 10px;
$table-bg-color: $bg-color;

// blockquote
$blocquote-padding: 1rem 2rem;
$blocquote-decoration-border: 3px solid #ccc;

// mark
$mark-bg: #ff0;
$mark-color: #000;

// code
$code-font-family: $font-family-monospace;
$code-color: #f15c5c;
$code-kbd-bg-color: #ccc;


