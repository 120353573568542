.logo-container{
	a{
		.cercle,
		.letter {
			fill: #c02102;
			transition: fill 0.2s ease-out;
		}
	}
	
	a:hover{
		.cercle,
		.letter{
			fill: $color-grey;
		}
	}
}