.search-form-advanced-wrapper{
	background-color: $color-grey-light;
	padding: 25px 0;
	
	form.search-form-advanced{
		width: 100%;
		
		fieldset{
			border: none;
			padding: 0;
			
			//@include flex-row;
			//display: flex;
			
			legend{
				width: 100%;
				float: none;
				display: none;
			}
			
			.field-group{
				@include flex-row;
			}
			
			.form-field{
				@include col($m:4, $grid:14);
				padding: 0;
				margin: 0 0 15px;
				
				&:last-child{
					margin-bottom: 0;
				}
				
				@include breakpoint{
					margin: 0;
					padding: 0 15px;
				}
			}
			
			.form-field.submit-search-field{
				@include col($m:2, $grid:14);
				padding: 0;
				margin: 0;
				
				@include breakpoint{
					margin: 0;
					padding: 0 15px;
				}
			}
			
			input,
			select{
				margin: 0;
			}
		}
		
		.text-field-search{
			position: relative;
			
			button{
				position: absolute;
				top: 50%;
				right: 25px;
				transform: translateY(-50%);
				border: none;
				width: auto;
				height: auto;
				padding: 0;
				
				span{
					display: none;
				}
				
				&:hover{
					color: $color-primary;
					background-color: transparent;
				}
			}
		}
	}
	
	//  ---------------------------------------------~@
	
	
	
	.submit-search-field button {
		width: 100%;
		padding: 9px 15px;
		box-sizing: border-box;
	}
	
}
