@charset 'UTF-8';


/// Mixin needed for wrap columns
/// @group Grid
/// @output Generates center container with fixed width
/// @param {boolean} $full-width [false] - if true the container si full width
@mixin container($full-width: false) {
  @if not $full-width {
    max-width: $container-width;
  }
  margin: auto;
  width: 95%;
}

/// Mixin needed for wrap columns
/// @group Grid
/// @output Generates center container with fixed width
/// @param {boolean} $full-width [false] - if true the container si full width
@mixin flex-container($full-width: false) {
  @if not $full-width {
    max-width: $container-width;
  }
  margin: auto;
  width: 95%;
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@mixin flex-wrap(){
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

@mixin row() {
  margin-left: -$gutter-hor;
  margin-right: -$gutter-hor;
}


@mixin flex-row(){
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

/// Mixin que controla ancho de las columnas seguns la medida de la ventana del navegador.
/// @param {number} $grid [$grid-default] - el número de columnas que tendra la columna
/// @param {number} $xl [$grid] - el ancho en columnas que tendra $screen-xl
/// @param {number} $l [$grid] - el ancho en columnas que tendra $screen-l
/// @param {number} $m [$grid] - el ancho en columnas que tendra $screen-m
/// @param {number} $s [$grid] - el ancho en columnas que tendra $screen-s
/// @param {number} $xs [$grid] - el ancho en columnas que tendra $screen-xs
/// @example
///   .sidebar{
///    @include col($m:3);
///  }
/// si no se incluye el parametro el valor de ancho sera 100%

@mixin col(
  $grid: $grid-default,
  $xl: $grid,
  $l: $grid,
  $m: $grid,
  $s: $grid,
  $xs: $grid,
  $padding: true) {
  //
  $width: percentage($xs / $grid);
  position: relative;
  box-sizing: border-box;
  
  @if $padding == true {
    padding: $gutter-ver $gutter-hor;
  }
  
  //@if $xs != $grid {
  //  width: percentage($xs / $grid);
  //} @else {
    // configuracio per defecte per dispositius mòvils
    width: $width;
  //}

  // només es crea la mediaquery si es diferent de 100% de l'ample
  @if $s != $grid {
    @media only screen and (min-width: $screen-s) {
      width: percentage($s / $grid);
    }
  }

  @if $m != $grid {
    @media only screen and (min-width: $screen-m) {
      width: percentage($m / $grid);
    }
  }

  @if $l != $grid {
    @media only screen and (min-width: $screen-l) {
      width: percentage($l / $grid);
    }
  }

  @if $xl != $grid {
    @media only screen and (min-width: $screen-xl) {
      width: percentage($xl / $grid);
    }
  }
} // end @mixin col()


// @mixin offset controla el margen izquierdo de las columnas
@mixin offset(
  $grid: $grid-default,
  $xl: 0,
  $l: 0,
  $m: 0,
  $s: 0,
  $xs: 0) {
  // només es crea si s'ha passat parametres al mixin
  @if $xs != 0 {
    margin-left: percentage($xs / $grid);
  }

  @if $s != 0 {
    @media only screen and (min-width: $screen-s) {
      margin-left: percentage($s / $grid);
    }
  }

  @if $m != 0 {
    @media only screen and (min-width: $screen-m) {
      margin-left: percentage($m / $grid);
    }
  }

  @if $l != 0 {
    @media only screen and (min-width: $screen-l) {
      margin-left: percentage($l / $grid);
    }
  }

  @if $xl != 0 {
    @media only screen and (min-width: $screen-xl) {
      margin-left: percentage($xl / $grid);
    }
  }
} // end @mixin offset()

// @mixin opull
@mixin pull(
  $grid: $grid-default,
  $xl: 0,
  $l: 0,
  $m: 0,
  $s: 0,
  $xs: 0) {

  // només es crea si s'ha passat parametres al mixin
  @if $xs != 0 {
    right: percentage($xs / $grid);
  }

  @if $s != 0 {
    @media only screen and (min-width: $screen-s) {
      margin-left: percentage($s / $grid);
    }
  }

  @if $m != 0 {
    @media only screen and (min-width: $screen-m) {
      right: percentage($m / $grid);
    }
  }

  @if $l != 0 {
    @media only screen and (min-width: $screen-l) {
      right: percentage($l / $grid);
    }
  }

  @if $xl != 0 {
    @media only screen and (min-width: $screen-xl) {
      right: percentage($xl / $grid);
    }
  }
} // end @mixin pull

//
@mixin push(
  $grid: $grid-default,
  $xl: 0,
  $l: 0,
  $m: 0,
  $s: 0,
  $xs: 0) {

  // només es crea si s'ha passat parametres al mixin
  @if $xs != 0 {
    left: percentage($xs / $grid);
  }

  @if $s != 0 {
    @media only screen and (min-width: $screen-s) {
      left: percentage($s / $grid);
    }
  }

  @if $m != 0 {
    @media only screen and (min-width: $screen-m) {
      left: percentage($m / $grid);
    }
  }

  @if $l != 0 {
    @media only screen and (min-width: $screen-l) {
      left: percentage($l / $grid);
    }
  }

  @if $xl != 0 {
    @media only screen and (min-width: $screen-xl) {
      left: percentage($xl / $grid);
    }
  }
} //end @mixin push
