@charset 'UTF-8';

@mixin clear {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}


@mixin reset-list{
  &{
    margin: 0;
    padding: 0;
  }
  
  li{
    list-style: none;
    margin: 0;
    display: inline;
  }
}



@mixin font-size($size) {
  font-size: px-rem($size);
}

// transitions
@mixin transition($args: $transition) {
  transition: $args;
}

/// Converts image to responsive
@mixin img-responsive {
  width: 100%;
  height: auto;
}



/// Aspect-ratio for image containers
@mixin aspect-ratio($ratioX, $ratioY){
  position: relative;
  height: 0px;
  width: 100%;
  padding-bottom: percentage($ratioY / $ratioX);
  overflow: hidden;
  
  img{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    height: auto;
    width: 100%;
  }
}


@mixin list-hor($align: left) {
  
  & {
    text-align: $align;
    padding: 0;
    font-size: 0;

    li {
      display: inline-block;
      list-style: none;
      margin: 0;
    }
  }
}

// menu de navegacion horizontal
@mixin nav-ver($align: left, $reset: false) {
  text-align: $align;

  .list-heading {
    display: block;
  }

  ul {
    @if $reset {
      margin: 0;
    }

    a,
    li {
      display: block;
    }

    li {
      @if $reset {
        margin: 0;
      }

      list-style: none;
    }
  }
}

//
@mixin nav-bar-ver($align: left) {
  a {
    border-bottom: 1px solid lighten($navbar-bg, 80%);
    padding: $gap-ver $gap-hor;
    color: $navbar-link-color;
    background-color: $navbar-link-color-bg;
    text-align: $align;

    &:hover {
      color: $navbar-link-color-hover;
      background-color: $navbar-link-color-bg-hover;
    }

    &.active {
      color: $navbar-link-active-color;
      background-color: lighten($navbar-link-color-bg, 20%);
      cursor: default;
    }
  }
}

/// Converts a list in a responsive navbar
@mixin nav-bar($align: left, $style: true) {
  &,
  ul {
    display: block;
    font-size: 0;
    margin: 0;

    li {
      list-style: none;
      display: block;
      font-size: $font-size-default;
      margin: 0;

      //position: relative;

      // dropdown first level
      ul {
        display: none;
        width: auto;
        float: none;
      }

      &:hover {
        ul {
          display: block;
        }
      }

      // comportamiento cuando està extendido
      @include breakpoint {
        //@if $align == right {
          float: $align;
        //}

        ul {
          width: auto;
          position: absolute;
          float: none;
          min-width: 160px;

          li {
            display: block;
            float: none;
          }
        }

      }// end breakpoint
    }// end li first level

  }// ul

  // style for links
  @if $style {
    a {
      // links

      display: block;
      padding: 0 15px;
      color: $navbar-link-color;
      background-color: $navbar-link-color-bg;
      border-bottom: 1px solid lighten($navbar-bg, 80%);
      line-height: 4rem;

      &:hover {
        color: $navbar-link-color-hover;
        background-color: $navbar-link-color-bg-hover;
      }

      &.active {
        color: $navbar-link-active-color;
        background-color: lighten($navbar-link-color-bg, 20%);
        cursor: default;
      }

      // media query
      @media only screen and (min-width: $breakpoint) {
        //display: inline-block;
        border: 0;
        border-right: 1px solid lighten($navbar-bg, 80%);
      }
    }
  } // endif $style

  // clear objects
  @include clear;

}// @mixin nav-bar

/// Converts an element in a button
@mixin button {
  text-align: center;
  display: inline-block;
  padding: $button-padding;
  color: $button-color;
  background-color: $button-bg;
  border: 2px solid $button-border-color;

  border-radius: $button-border-radius;
  @include transition;

  cursor: pointer;

  &:hover {
    color: $button-color-hover;
    background-color: $button-bg-hover;
    border: 2px solid $button-border-color-hover;
  }
}
