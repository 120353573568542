.featured-downloads{
	ul{
		@include flex-row;
	}
	
	li{
		@include col($xs:6);
		list-style: none;
		display: block;
		text-align: center;
		margin: 0;
		
		a{
			display: flex;
			display: block;
			padding: 30px;
			border: 2px solid $color-primary;
			border-radius: 8px;
			height: 100%;
			overflow: hidden;
			position: relative;
			
			&,
			&:before,
			&:after{
				transition: $transition;
			}
			
			
			
			//&:before{
			//	content: "";
			//	display: block;
			//	position: absolute;
			//	top: 0;
			//	left: 0;
			//	right: 0;
			//	bottom: 0;
			//	background-color: transparent;
			//}
			//
			//&:after{
			//	color: transparent;
			//	content: '\e80d';
			//	font-family: fontello;
			//	display: block;
			//	position: absolute;
			//	top: 50%;
			//	left: 0;
			//	right: 0;
			//	font-size: 3rem;
			//	transform: translateY(-50%);
			//}
			
			i{
				display: block;
				font-size: 3rem;
			}
			
			&:hover{
				//color: $color-primary;
				transform: scale(1.05);
				background-color: $color-primary;
				color: #fff;
				
				//&:before{
				//	background-color: $color-primary;
				//}
				//
				//&:after{
				//	color: #fff;
				//}
			}
		}
	}
}