@charset 'UTF-8';

dl,
dt,
dd,
ol,
ul,
li {
  margin: $list-margin;
  padding: 0;
}

dl {
  margin: $text-block-margin;

  dt {
    font-weight: bold;
  }

  dd {
    margin: $list-dd-margin;
  }
}

ul,
ol {
  list-style-position: inside;

  li {
    li {
      margin-left: 20px;
    }
  }
}
